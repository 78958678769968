.logo{
    width: 300px;
    height: 150px;
    margin-bottom: 30px;
}
/* form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */
input{
  
    /* width: 350px;
    height:40px;
    border: 0.8px rgb(204, 201, 201) solid;
    border-radius: 5px;
    background-color: white;
    padding: 8px; */
}
/* 
input:focus {
    border: 0.8px #F9A826 solid;
    outline:none;
} */

form .forget-password{
    margin-left: 224px;
    color:rgb(100, 95, 95);
}
/* .custome-btn
{
    background-color: #F9A826 !important;
    color: white !important;
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    width: 350px !important;
    margin-top: 20px !important;
    font-weight: 500 !important;
}

.signUp{
    color:rgb(100, 95, 95);
} */
.signUp span{
    color: #F9A826;
    font-weight: 500
}