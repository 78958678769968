.custome-btn
{
    background-color: #F9A826 !important;
    color: white !important;
    border: none !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.sub-title{
    justify-content: flex-start;
    display: flex;
    font-size: 21px;
    font-weight: 400;

}

.title{
    display: flex;
    font-size: 28px;
    font-weight: 700;
}

.details{
    text-align: justify;
}


.pizza-img{
    height: 299px;

    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: 0px 0px 1px 14px #3e424ab8;
    margin-top: 2rem;
}
.left-side{
    align-self: center;
}