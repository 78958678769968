.logo {
    width: 50px;
    display: flex;

}
.cart {
    display: flex;
    flex-direction: row;
    width: 44px;
    justify-content: center;
    flex-grow: 1;
    margin-right: 0.5rem;
    font-weight: 200;
}

.active{
    text-decoration: underline;
    text-decoration-color: #e1b168;
    text-decoration-thickness:2px ;
}

.right-header {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
}

.count-cart{
    display: flex;

     width: 20px;
     height: 20px;
     background: #e1b168;
     border-radius: 50%;
     text-align: center;
     justify-content: center;
     align-items: center;
 }
 .ri-shopping-cart-line, .ri-menu-line {
    font-size: 22px;
}