.Hamburger{
    display: flex;
    flex-flow: column nowrap;
    z-index: 1;
    width: 50%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.836);
    overflow-x: hidden;
    left: 50%;
    align-items: center;
}