.logo{
    width: 300px;
    height: 150px;
    margin-bottom: 30px;
}



.form .forget-password{
    margin-left: 195px;
    color:rgb(100, 95, 95);
}


.ErrorMessage{
    border-left: 4.5px solid #ff2111;
    background-color: #f18c885c;
   
    height: 2.4rem;
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center;

}