
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;700&display=swap");

.App{
    /* background-image: linear-gradient(to top, rgba(255,0,0,0), #f7ab317a); */
    font-family: "Josefin Sans", sans-serif;
}


