.model{
    width: 38rem;
}

.img-model{
    width: 15rem;
}

.close-icon{
    font-size: 27px;
    display: flex;
    justify-content: end;
    margin-right: 10px;
}

.star{
   font-size: 1.3rem;
}